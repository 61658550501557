const cdnStaticFile = process.env.RAZZLE_CDN_STATIC_URL;

const versions = {
	img: {
		logo: "26",
		screens: "2023_2",
		videos: "2023_1",
		payments: "2024_1",
		vipIcons: "2023_3",
	},
	other: "2022_1",
	default: "2022_1",
};

const trimLeftStr = (str, charlist) => {
	// eslint-disable-next-line no-param-reassign
	if (charlist === undefined) charlist = "s";
	return String(str).replace(new RegExp(`^[${charlist}]+`), "");
};

const getVersionOfType = (type) => {
	switch (type) {
		case "logo":
			return versions.img.logo;
		case "screens":
			return versions.img.screens;
		case "videos":
			return versions.img.videos;
		case "payments":
			return versions.img.payments;
		case "vip-icons":
			return versions.img.vipIcons;
		case "other":
			return versions.other;
		default:
			return versions.default;
	}
};

const staticFileHelper = (url = "", type = "") => {
	return `${cdnStaticFile}/${trimLeftStr(url, "/")}?v=${getVersionOfType(
		type
	)}`;
};

export default staticFileHelper;

import Block from "components/layout/Block";
import Text from "components/typography/Text";

const ErrorMessage = ({ children, ...rest }) => (
	<Block mt={2} {...rest}>
		<Text color="landingErrorMessage">{children}</Text>
	</Block>
);

export default ErrorMessage;

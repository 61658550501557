// ** React Imports
import { useHistory } from "react-router-dom";

// ** Redux & Store & Actions
import { useDispatch, useSelector } from "react-redux";
import { getUser, setLanguage } from "store/modules/global";

// ** Routes
import routes from "containers";

// #####################################################

export const useGetUserAfterLogin = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const language = useSelector((state) => state.global.language);

	const getUserAfterLogin = () => {
		return dispatch(getUser()).then((res) => {
			const userLanguage = res.result.data?.user_language || "pl";
			const frozen = res.result.data?.frozen || false;

			if (language !== userLanguage) {
				dispatch(
					setLanguage({
						language: userLanguage,
						auth: false,
						setCookie: true,
					})
				);
			}

			if (frozen) {
				history.push(`${routes.userpanel.path}/settings/account`);
			}

			return res;
		});
	};

	// #####################################################

	return {
		getUserAfterLogin,
	};
};

// #####################################################

// ** React Imports
import { useRef } from "react";

// ** Redux & Store & Actions
import { useSelector, useDispatch } from "react-redux";
import { setFingerprint } from "store/modules/global";

// ** Utils & Helpers
import api from "helpers/ApiClient";

// #####################################################

function isString(value) {
	return typeof value === "string" || value instanceof String;
}
// #####################################################

export const useFingerprint = () => {
	const actualHash = useSelector(
		({ global: { fingerprint } }) => fingerprint
	);

	const dispatch = useDispatch();

	const newHash = useRef("");

	const getFingerprint = async () => {
		if (Boolean(actualHash) && actualHash && isString(actualHash)) {
			api.addFingerprint(actualHash);
			newHash.current = actualHash;
			return actualHash;
		}

		const fingerprintJS = await import("@fingerprintjs/fingerprintjs");
		const Fingerprint = fingerprintJS.default;
		const fingerprintLoad = await Fingerprint.load();
		const fingerprintAgent = await fingerprintLoad.get();

		const visitorId = fingerprintAgent.visitorId;

		if (visitorId) {
			api.addFingerprint(visitorId);
			dispatch(setFingerprint(visitorId));
			newHash.current = visitorId;
			return visitorId;
		} else {
			newHash.current = "brak_danych";
			return "brak_danych";
		}
	};

	// #####################################################

	return {
		fingerprint: newHash.current,
		getFingerprint: getFingerprint,
	};
};
